<template>
  <div class="home">
    <div style="width: 100vw;height:147.7vw;position: relative;">
      <img src="@/assets/home.png" style="width: 100%;z-index: -1" />
      <div
        style="position: absolute;left:31.3vw;top:39.2vw;width:37.3vw;height:40vw"
        @click="toMenu(2)"
      ></div>
      <div
        style="position: absolute;left:31.3vw;top:91.7vw;width:37.3vw;height:40vw"
        @click="toMenu(1)"
      ></div>
      <img src="@/assets/menu-head.jpg" style="display: none" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    toMenu(type) {
      this.$router.push({ path: "/menu", query: { type: type } });
    },
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  width: 100%;
  background-image: url("~@/assets/home-bg.jpg");
  background-color: #00643c;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

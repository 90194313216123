<template>
  <router-view :key="$route.fullPath" />
</template>

<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}


.dplayer-full-in-icon {
  display: none !important;
}
</style>
